import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Page from '../../components/Page';
import LogosBlock from '../../components/Logos/block';
import Location from '../../components/Location';

import styles from './product.module.css';

const ProductPage = ({ data, params }) => {
  const { url } = params;
  const productData = data.allProductsJson.edges
    .map((x) => x.node)
    .find((x) => x.url === url);
  return (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content:
              'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
          },
          {
            name: 'keywords',
            content:
              'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
          },
        ]}
      >
        <html lang="sv" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Helmet>
      <div className={styles.content}>
        <Header className={styles.headerBackground} />
        <Page bgColor="#FDF7FC">
          <section>
            <h1 style={{ color: '#E830B0' }} className={styles.title}>
              {productData.title}
            </h1>
            <h2>{productData.header}</h2>
            <p>{productData.subheader}</p>
            {productData.externalUrl ? (
              <a
                target="_blank"
                className={styles.link}
                href={productData.externalUrl}
              >
                <button>Go to site >></button>
              </a>
            ) : null}
          </section>
          <main>
            <div className={styles.line}></div>
            {productData.blocks.map((block, i) => {
              if (i % 2 === 0) {
                return (
                  <section key={i} className={styles.section}>
                    <div className={styles.sectionText}>
                      <h3>{block.title}</h3>
                      <p>{block.text}</p>
                    </div>
                    <div className={styles.sectionIllustration}>
                      <img src={`/images/${block.image}`} />
                    </div>
                  </section>
                );
              } else {
                return (
                  <section key={i} className={styles.section}>
                    <div className={styles.sectionIllustration}>
                      <img src={`/images/${block.image}`} />
                    </div>
                    <div className={styles.sectionText}>
                      <h3>{block.title}</h3>
                      <p>{block.text}</p>
                    </div>
                  </section>
                );
              }
            })}
          </main>
        </Page>
        <LogosBlock
          id="logos"
          logos={data.allLogosJson.edges.map((x) => x.node)}
        />
        <Location title={data.site.siteMetadata.title} id="location" />
        <Footer
          products={data.allProductsJson.edges.map((x) => x.node)}
          cases={data.allCasesJson.edges.map((x) => x.node)}
          title={data.site.siteMetadata.title}
        />
      </div>
    </div>
  );
};

export default ProductPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
          title
          header
          subheader
          externalUrl
          blocks {
            title
            text
            image
          }
        }
      }
    }
  }
`;
